import AOS from 'aos';


jQuery(document).ready(() => {

  let header = $('.main-header')
  let headerHeight = document.getElementsByClassName('main-header')[0].offsetHeight;
  let pos = $('.main-header').offset().top + 300;
  let posHome = $('.main-header').offset().top + 600;
  let win = $(window);
  let tooltip = $('.tooltip-header.show')
  let tooltipHeight = null;

  if(tooltip.length > 0) {
    console.log(tooltip.length > 0)
    tooltipHeight = document.getElementsByClassName('.tooltip-header')[0].offsetHeight;
  }

  win.on('scroll', function () {
    if($('body.home').length > 1) {
      win.scrollTop() >= posHome  ? header.addClass('header-sticky') && tooltip.addClass('hidden') && $('.wrap').css({'margin-top': (tooltipHeight > 0 ? headerHeight - tooltipHeight : headerHeight) + 'px'}) : header.removeClass('header-sticky') && tooltip.removeClass('hidden') && $('.wrap').css({'margin-top': '0'});
    } else {
      win.scrollTop() >= pos  ? header.addClass('header-sticky') && tooltip.addClass('hidden') && $('.wrap').css({'margin-top': (tooltipHeight > 0 ? headerHeight - tooltipHeight : headerHeight) + 'px'}) : header.removeClass('header-sticky') && tooltip.removeClass('hidden') && $('.wrap').css({'margin-top': '0'});
    }
  });

  /* Toltip start */
  $('.tooltip-header .close-button').on('click', function () {
    $(this).closest('.tooltip-header').fadeOut();
    sessionStorage.setItem('banner_closed', true);
  });

  const isClosed = sessionStorage.getItem('banner_closed');
  if (!isClosed) $('.tooltip-header').addClass('show');
  /* Toltip end */

  /* Close All Modals */
  $(document).on('click', '.close-modal', function () {
    $(this).closest('.modal').removeClass('active');
  });

  $('a[href="#request-form"]').on('click', function () {
    $('#request-form').addClass('active');
  });

  $('#open-menu, #close-menu').on('click', function () {
    $('.mobile-menu').toggleClass('active');
  });

  /* AOS Animations */
  AOS.init({ once: true, duration: 1500 });

  let scrollRef = 0;

  window.addEventListener('scroll', function () {
    // increase value up to 10, then refresh AOS
    scrollRef <= 10 ? scrollRef++ : AOS.refresh();
  });
});
